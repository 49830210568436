.base {
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 3px 10px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  gap: 5px;

  svg {
    scale: 0.9;
    height: 14px;
  }
}

.agents-mic-check {
  background: rgba(0, 189, 80, 0.3);
  color: rgba(0, 189, 80, 1);

}

.agents-installations {
  background: rgba(42, 115, 255, 0.3);
  color: rgba(42, 115, 255, 1);
}

.managers,
.team {
  background: rgba(36, 36, 36, 1);
  color: white;
}