.tooltip {
    position: absolute;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: var(--select-z-index);
    box-shadow: 0 10px 40px 0 #16141433;
    border-radius: 20px;
}

.tooltip.info {
    top: 0;
    transform: translate(-50%, calc(-100% - 8px));
}

.tooltip.flag {
    bottom: 0;
    transform: translate(-50%, calc(100% + 10px));
}

.text {
    position: relative;
    display: flex;
    width: max-content;
}

.flag .text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 80px;
    padding: 25px 0;
    border-radius: 20px;
    background: var(--primary-bg-color);
}

.info .text {
    max-width: 250px;
    padding: 14px 15px 14px 19px;
    background: var(--primary-input-bg);
    border: solid 1px var(--primary-bg-color);
    border-radius: 10px;
}

.flag .textContent {
    font-size: var(--font-size-22);
}

.info .textContent {
    font-size: var(--font-size-14);
}

.arrow {
    position: absolute;
    left: 50%;
    width: 0;
    height: 0;
}

.flag .arrow {
    top: 0;
    transform: translate(-50%, -100%);
    border: 10px solid var(--primary-bg-color);

    /* border: 7px solid var(--primary-action-color); */
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
}

.info .arrow {
    bottom: 0;
    transform: translate(-50%, 100%);
    border: 7px solid var(--primary-bg-color);
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
}

@media(width <=1400px) {
    .flag .text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 60px;
        padding: 0 0 20px;
        border-radius: 20px;
        background: var(--primary-bg-color);
    }

    .info .textContent {
        font-size: var(--font-size-16);
    }
}

@media(width <=1024px) {
    .tooltip.info {
        top: 0;
        transform: translate(-75%, calc(-100% - 8px));
    }

    .flag .text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 60px;
        padding: 0 0 20px;
    }

    .info .text {
        max-width: 200px;
        padding: 8px 16px;
    }

    .flag .textContent {
        font-size: var(--font-size-16);
    }

    .info .textContent {
        font-size: var(--font-size-14);
    }

    .info .arrow {
        left: 75%;
        bottom: 0;
        transform: translate(-50%, 100%);
    }
}