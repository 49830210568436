.container {
    padding: 30px 40px 45px !important;
    /* max-height: 90vh;
    overflow-y: auto; */
}

.container button {
    border-radius: 16px !important;
}

.modal_wrapper>div {
    width: 100% !important;
    max-width: 1122px;
}

.body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.title {
    color: var(--primary-title-color);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 8px;
}

.subtitle {
    color: var(--third-text-color);
    margin-bottom: 30px;
}

.company-name {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid rgba(171, 171, 171, 0.3);
    padding-bottom: 20px;
    display: flex;
    gap: 12px;
}

.company-name span {
    color: var(--primary-action-color);
}

.inputs {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    margin-top: 10px;
    max-height: 42vh;
    overflow: auto;
    padding: 2px;
    padding-right: 15px;
    margin-right: -15px;
}

.inputs::-webkit-scrollbar,
.inputs::-webkit-scrollbar-thumb,
.inputs::-webkit-scrollbar-track {
    border-radius: 100vmax;
}


.input-row {
    display: flex;
    column-gap: 10px;
    justify-content: center;
}

.text-input {
    width: 50%;
}

.select-input {
    width: 25%;
}

.select-input span {
    font-size: 14px !important;
}

.select-input input {
    height: 54px !important;
}

.add-person-btn {
    height: 32px;
    width: 110px;
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
}

.action-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 15px;
}

.action-buttons button {
    font-size: 14px;
    height: 62px;
    width: 180px;
}

.action-buttons button.cancel {
    background-color: var(--secondary-bg-color);
    color: var(--primary-text-color);
}

.action-buttons button.send {
    background-color: var(--secondary-action-color);
}

.container button.add-person-btn {
    border-radius: 10px !important;
}

/*
.modal_bottom_btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
} */

@media (max-width: 575px) {
    .container {
        padding: 22px !important;
    }

    .title {
        text-align: center;
        font-size: 22px;
        line-height: 1.3;
        margin-block: 0 5px;
    }

    .subtitle {
        text-align: center;
        margin-bottom: 24px;
    }

    .company-name {
        justify-content: space-between;
    }

    .input-row {
        flex-wrap: wrap;
    }

    .input-row:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 16px;
        border-bottom: 1px solid rgba(217, 217, 217, 0.5);
    }

    .text-input {
        width: 100%;
    }

    .select-input {
        width: calc(50% - 5px);
    }

    .add-person-btn {
        margin-top: 15px;
        margin-left: auto;
    }

    .action-buttons {
        margin-top: 28px;
    }

    .action-buttons button {
        flex: 1;
    }
}