.container {
    display: flex;
    justify-content: space-between;
    padding: 0 6px;
    align-items: center;
    font-size: 14px;
    width: 200px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--primary-title-color);
}

.buttonActive {
    border-radius: 8px;
    background: #fff;
    height: 34px;
    flex-shrink: 0;
    border: #fff;
    width: 94px;
}

.buttonInnactive {
    color: #fff;
    background: transparent;
    border: 0;
    width: 94px;
}

.page-header {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.page-header h1 {
    font-size: 26px;
}

.page-header .call-to-action {
    margin-left: 20px;
    height: 32px;
    width: 70px;
    font-size: 13px;
}

@media (max-width: 991px) {
    .page-header {
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
    }
}