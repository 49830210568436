@import url('https://fonts.cdnfonts.com/css/satoshi');

@import url('./reset');
@import url('./variables/global.css');
@import url('./themes/light.css');

html {
    scroll-behavior: smooth;
}

.app {
    display: flex;
    font-family: var(--font-family-main);
    background: var(--primary-bg-color);
    min-height: 100vh;
    flex-wrap: wrap;
}

.modal-open {
    height: 100%;
    overflow: hidden;
}

.ant-tooltip-inner {
    color: #000 !important;
    padding: 16px 18px !important;
    border-radius: 12px !important;
}

.ant-modal-mask {
    backdrop-filter: blur(15px);
}

.StripeElement--focus {
    border: 1px solid var(--primary-action-color);
}

.StripeElement--invalid {
    border: 1px solid var(--secondary-action-text-color);
}

@media (max-width: 991px) {
    .app {
        display: block;
    }
}

/* Skeleton */
.skeleton {
    width: 100%;
    height: 74px;
    background: var(--primary-option-button-bg);
    animation: breathing 2s ease-in-out infinite;
    border-radius: 14px;
}

@keyframes breathing {
    0% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0.4;
    }
}

.ant-dropdown-menu-item-group-title {
    font-weight: 700;
    margin-top: 10px;
}
