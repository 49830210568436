.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.small {
    width: 40px;
    height: 46px;
}

.small div {
    width: 26px;
    height: 26px;
    border: 5px solid var(--primary-action-color);
}

.lds-ring {
    width: 80px;
    height: 80px;
}

.lds-ring div {
    width: 64px;
    height: 64px;
    border: 8px solid var(--primary-action-color);
}

.common {
    display: inline-block;
    position: relative;
}

.common div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 8px;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary-action-color) transparent transparent transparent;
}

.common div:nth-child(1) {
    animation-delay: -0.45s;
}

.common div:nth-child(2) {
    animation-delay: -0.3s;
}

.common div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}