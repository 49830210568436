.dropdown-container {
    position: relative;
    height: 100%;
}

.dropdown-button {
    height: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: var(--font-size-16);
    color: var(--primary-title-color);
    font-weight: var(--font-weight-m);
    background: transparent;
    border: none;
    cursor: pointer;
}

.dark .dropdown-button {
    color: var(--reversed-text-color);
}

.dropdown {
    position: absolute;
    z-index: var(--modal-z-index);
    top: 75px;
    background: var(--primary-bg-color);
    padding: 30px 34px;
    border-radius: 15px;
    box-shadow: 0 10px 40px 0 #16141414;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dark .dropdown {
    background: var(--third-bg-color);
}

.overlay {
    position: fixed;
    z-index: var(--overlay-z-index);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
}

@media(width <=1400px) {
    .dropdown-container {
        height: auto;
    }

    .dropdown-button {
        height: auto;
    }

    .dropdown {
        padding: 23px 25px 13px 23px;
        gap: 14px;
        top: 45px;
    }
}

@media(width <=1024px) {
    .dropdown-container {
        width: 100%;
        position: relative;
        height: auto;
        max-height: 60px;
        overflow: hidden;
        transition: all 1s;
    }

    .dropdown-container.open {
        height: auto;
        max-height: 390px;
    }

    .dropdown-button {
        height: auto;
        position: relative;
        width: 100%;
        padding: 20px 31px 20px 41px;
        gap: 0;
        justify-content: space-between;
        font-size: var(--font-size-20);
        transition: all 2s;
    }

    .open .dropdown-button {
        background: var(--primary-divider-color);
        transition: none;
    }

    .open.dark .dropdown-button {
        background: var(--primary-icon-bg);
    }

    .dropdown {
        position: relative;
        z-index: var(--modal-z-index);
        top: 0;
        background: var(--primary-divider-color);
        padding: 10px 0 35px 41px;
        border-radius: 0;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

    .dark .dropdown {
        background: var(--primary-icon-bg);
    }

    .overlay {
        display: none;
    }

    .open .overlay {
        display: block;
        position: fixed;
        z-index: var(--overlay-z-index);
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: transparent;
    }

    .icon {
        transform: scale(1.7);
    }
}