.container {
    position: relative;
    width: 20px;
    height: 20px;
}

.button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.button:hover {
    background: white;
}