.card {
    flex: 1 1 0px;
    position: relative;
    background: var(--primary-bg-color);
    border-radius: 12px;
    box-shadow: 0 5px 30px 0 rgb(125 123 123 / 12%);
    flex-grow: 1;
    padding: 26px 36px;
}

@media (max-width: 1400px) {
    .card {
        padding: 20px;
    }
}