.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label-text {
    color: var(--reversed-text-color);
    font-weight: var(--font-weight-l);
    font-size: 12px;
    line-height: 27px;
}

.double-line .label-text {
    width: 200px;
}

.input {
    padding: 22px 33px;
    border: none;
    width: 100%;
    height: 68px;
    color: var(--reversed-text-color);
    font-weight: var(--font-weight-m);
    font-size: var(--font-size-16);
    background: var(--primary-input-bg);
    border-radius: 16px;
    display: flex;
    align-items: center;
}

.input::placeholder {
    color: var(--third-text-color);
    font-weight: var(--font-weight-m);
    font-size: var(--font-size-16);
}

.input:focus {
    outline: solid 1px var(--primary-action-color);
}

.error .input:focus {
    outline: solid 1px var(--third-action-color);
}

.error .input {
    outline: solid 1px var(--third-action-color);
}

.area {
    resize: none;
    height: auto;
}

.errorMessage {
    color: var(--third-action-color);
    line-height: 27px;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-m);
}

@media(width <=1438px) {
    .label-text {
        font-size: var(--font-size-18);
    }

    .double-line .label-text {
        width: 150px;
    }

    .area {
        resize: none;
        height: auto;
    }
}

@media(width <=1024px) {
    .container {
        gap: 8px;
    }

    .label-text {
        font-size: var(--font-size-12);
    }

    .double-line .label-text {
        width: 100%;
    }

    .input {
        padding: 16px 0 15px 27px;
        height: 58px;
        font-size: var(--font-size-15);
    }

    .input::placeholder {
        font-size: var(--font-size-15);
    }

    .area {
        resize: none;
        height: auto;
    }

    .errorMessage {
        line-height: 27px;
        font-size: var(--font-size-12);
    }

    .reduced .input {
        padding: 16px 0 15px 20px;
        height: 48px;
        font-size: var(--font-size-14);
    }

    .reduced .input::placeholder {
        font-size: var(--font-size-14);
    }

    .reduced .area {
        resize: none;
        height: auto;
    }
}