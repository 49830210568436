.main {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 10%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.card {
    margin-top: 20px;
    width: 500px;
    height: 280px;
    background-color: var(--secondary-bg-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    h2 {
        text-align: center;
    }
}