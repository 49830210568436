.table {
    width: 100%;
    border-collapse: collapse;
    padding: 0 20px;
    font-size: 13px;
    overflow: visible;
}

.table_responsive {
    overflow-x: auto;
    /* overflow-y: hidden;
    */
}

.action_button_wrapper {
    display: flex;
    justify-content: space-between;
}

.table-head {
    background-color: var(--secondary-bg-color);
}

.table-head th {
    text-align: left;
    color: var(--third-text-color);
    font-weight: 500;
    padding: 15px 0;
    padding-right: 10px;
    font-size: 12px;
}

.table-head th:first-of-type {
    padding-left: 30px;
}

.table-head th:last-of-type {
    padding-right: 30px;
    text-align: right;
}

.table-body tr td {
    min-width: 100px;
    padding: 0;
    height: 60px;
    position: relative;
    font-size: 14px;
    font-weight: 500;
}

.table-body tr td:first-of-type {
    padding-left: 30px;
}

.table-body tr td:last-of-type {
    padding-right: 30px;
    text-align: right;
    display: flex;
    justify-content: end;
}

.table-body tr td:not(:last-of-type) {
    padding-right: 20px;
}

.table-body tr:not(:last-of-type) td:not(:first-of-type):not(:last-of-type)::after {
    content: "";
    background: rgba(217, 217, 217, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
}

.table-body tr:not(:last-of-type) td:first-of-type::after {
    content: "";
    background: rgba(217, 217, 217, 0.5);
    position: absolute;
    left: 30px;
    right: 0;
    bottom: 0;
    height: 1px;
}

.table-body tr:not(:last-of-type) td:last-of-type::after {
    content: "";
    background: rgba(217, 217, 217, 0.5);
    position: absolute;
    left: 0;
    right: 30px;
    bottom: 0;
    height: 1px;
}

.no-data {
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    color: #ddd;
    padding: 50px 0;
}

.ant-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 30px;
    border-top: 1px solid rgba(217, 217, 217, 0.5);
}

.ant-pagination li:first-child {
    margin-right: auto;
}

.merge_row_table .table-head th:last-of-type {
    padding-right: 0;
}

.merge_row_table .table-body tr td:first-of-type {
    padding-left: 0;
}

.merge_row_table .table-body tr td[rowspan] {
    padding-left: 30px;
    width: 290px;
    font-weight: bold;
    font-size: 16px;
    vertical-align: top;
    padding-block: 18px;
}

.merge_row_table .table-body tr td[rowspan]:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background: rgba(217, 217, 217, 0.5);
}

.merge_row_table .table-body tr td:last-of-type {
    padding-right: 0;
}

.merge_row_table .table-body tr:not(:last-of-type) td:first-of-type::after {
    left: 0;
}

.merge_row_table .table-body tr:not(:last-of-type) td:last-of-type::after {
    right: 0;
}