.page-header {
    display: flex;
    align-items: center;
}

.page-header h1 {
    font-size: 26px;
}

.page-header .invite-button {
    margin-left: 20px;
    height: 32px;
    width: 105px;
    font-size: 13px;
}

.users-card {
    padding: 0;
    margin-top: 13px;
}

.account_info {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
}

.account_info span {
    font-size: 12px;
    font-weight: 500;
    color: var(--third-text-color);
}

.card-header {
    font-size: 16px;
    font-weight: 700;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-header .edit-button,
.card-header .cancel-button {
    height: 32px;
    width: 90px;
    font-size: 13px;
}

.card-header .cancel-button {
    background-color: #d9d9d9;
}

.full_name_edit {
    display: flex;
    gap: 10px;
}

.full_name_edit .text-input {
    width: 140px;
}

.users-card .input-wrapper,
.users-card .input-wrapper>div {
    border-radius: 10px !important;
    background-color: var(--secondary-bg-color);
}

.active {
    color: var(--primary-action-color);
}

.active:before {
    content: '';
    background-color: var(--primary-action-color);
    height: 7px;
    width: 7px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 7px;
    margin-bottom: 1px;
}

.inactive {
    color: var(--third-action-color);
}

.inactive:before {
    content: '';
    background-color: var(--third-action-color);
    height: 7px;
    width: 7px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 7px;
    margin-bottom: 1px;
}

.pending {
    color: var(--fourth-action-color);
}

.pending:before {
    content: '';
    background-color: var(--fourth-action-color);
    height: 7px;
    width: 7px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 7px;
    margin-bottom: 1px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--secondary-action-text-color);
    cursor: pointer;
    font-weight: 500;
    gap: 4px;
}

.actions svg path {
    fill: currentColor;
}

.actions span {
    margin-right: 7px;
}

.actions_disable {
    color: #DBDBDB;
}

.actions_disable svg path {
    fill: #DBDBDB;
}

.email {
    color: var(--secondary-action-color);
}

.delete-confirmation-text {
    display: block;
    text-align: center;
    padding: 0 10px;
    padding-top: 5px;
}

.delete-confirmation-text span {
    color: var(--third-action-color);
    font-weight: 700;
}

.text-input {
    height: 42px !important;
}

.edit-buttons {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.select-input {
    height: 42px !important;
    font-size: 13px !important;
}

.users_table thead th:nth-child(1) {
    min-width: 100px;
}

.users_table thead th:nth-child(2) {
    min-width: 100px;
}

.users_table thead th:nth-child(3) {
    min-width: 200px;
}

.users_table thead th:nth-child(4),
.users_table thead th:nth-child(5) {
    min-width: 100px;
}

.users_table thead th:nth-child(6) {
    width: 100px;
}


.users_edit_table thead th:nth-child(1),
.users_edit_table thead th:nth-child(2) {
    width: 190px;
}

.users_edit_table thead th:nth-child(3) {
    min-width: 240px;
}

.users_edit_table thead th:nth-child(4),
.users_edit_table thead th:nth-child(5) {
    min-width: 121px;
}

.badge {
    background: rgb(42, 115, 255, 10%);
    color: #2A73FF;
    border-radius: 100vmax;
    text-transform: capitalize;
    min-width: 86px;
    min-height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    width: 90%;
}

.badge_transparent {
    background: transparent;
    color: #161414;
    min-width: auto;
}

@media (max-width: 1440px) {
    .users_table thead th:nth-child(1) {
        min-width: 120px;
    }

    .users_table thead th:nth-child(2) {
        width: 160px;
    }

    .users_table thead th:nth-child(3) {
        min-width: 160px;
    }
}

@media (max-width: 1024px) {
    .card-header {
        padding: 20px;
    }
}

@media (max-width: 991px) {
    .card-header {
        padding: 0;
        margin-block: 25px 20px;
    }

    .page-header {
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .actions {
        margin-top: 10px;
    }
}


@media (max-width: 575px) {
    .edit-buttons {
        gap: 6px;
    }

    .card-header .cancel-button,
    .card-header .edit-button {
        width: 60px;
        font-size: 12px;
    }

    .users_table thead th:nth-child(1) {
        min-width: 120px;
    }

    .users_table thead th:nth-child(2) {
        min-width: 120px;
    }

    .users_table thead th:nth-child(3) {
        min-width: 180px;
    }

    .users_table thead th:nth-child(4),
    .users_table thead th:nth-child(5),
    .users_table thead th:nth-child(6) {
        min-width: 80px;
    }

    .users_table thead th:nth-child(7) {
        min-width: 100px;
    }

    .users_edit_table thead th:nth-child(1),
    .users_edit_table thead th:nth-child(2) {
        min-width: 160px;
    }

    .users_edit_table thead th:nth-child(4),
    .users_edit_table thead th:nth-child(5) {
        min-width: 130px;
    }
}