.container {
    position: relative;
    flex-grow: 1;
}

.container:has(.label) {
    padding-top: 14px;
    margin-top: 10px;
    font-size: 12px;
}

.label {
    position: absolute;
    top: 0;
    left: 21px;
    background-color: white;
    border-radius: 100vmax;
    color: var(--fourth-bg-color);
    font-weight: var(--font-weight-l);
    font-size: 11px;
    line-height: 27px;
    padding: 0px 18px;
    height: 24px;
    display: flex;
    align-items: center;
    font-family: var(--font-family-main);
}

.input {
    border: none;
    width: 100%;
    border-radius: 16px;
    height: 68px;
    /* background-color: var(--secondary-bg-color); */
    background: var(--secondary-bg-color);
    padding: 0 30px;
    color: var(--secondary-text-color);
    font-weight: 500;
    font-size: 14px;
}

.textarea {
    max-height: 200px;
    padding: 20px 30px;
    overflow-y: auto;
    resize: none;
    scrollbar-width: thin;
    scrollbar-color: var(--primary-action-color) transparent;
}

.textarea::-webkit-scrollbar {
    width: 8px;
    background-color: #ccc !important;
    border-radius: 16px;
    margin-right: 6px;
}

.textarea::-webkit-scrollbar-track {
    margin: 8px 0;
    border-radius: 16px;
}

.textarea::-webkit-scrollbar-thumb {
    background: var(--primary-action-color);
    border-radius: 16px;
    min-height: 40px;
}

.input::placeholder {
    color: var(--third-text-color);
    font-weight: 500;
}

.input:focus {
    outline: var(--primary-action-color) solid 1px;
}

.input:disabled {
    color: var(--third-text-color);
}

.input.error:not(:focus) {
    border: 1px solid var(--secondary-action-text-color);
    border-radius: 16px;
}

.view-password {
    position: absolute;
    top: 38px;
    bottom: 0;
    right: 28px;
    height: 22px;
    width: 22px;
    cursor: pointer;
}

.error-message {
    font-size: 13px;
    line-height: 14px;
    padding-left: 18px;
    padding-top: 2px;
    color: var(--secondary-action-text-color);
}

@media (max-width: 1024px) {
    .container:has(.label) {
        margin-top: 10px;
    }
}

@media (max-width: 575px) {
    .view-password {
        top: 35px;
        height: 20px;
        width: 20px;
    }

    .container:has(.label) {
        padding-top: 14px;
        margin-top: 6px;
    }

    .input {
        padding: 12px 26px;
        height: 58px;
        font-size: var(--font-size-14);
        font-size: 14px;
    }

    .label {
        left: 17px;
        font-size: 12px;
        padding: 0px 14px;
        height: 26px;
    }
}