.navigation-link {
    font-size: var(--font-size-16);
    color: var(--primary-title-color);
    font-weight: var(--font-weight-m);
    text-decoration: none;
}

.default {
    line-height: 18px;
}

.default.dark {
    color: var(--reversed-text-color);
}

.default:hover {
    color: var(--primary-action-color);
}

.action {
    color: var(--primary-action-text-color);
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-18);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary-action-color);
    border-radius: 10px;
}

.outlined {
    color: var(--primary-action-color);
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-18);
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 2px solid var(--primary-action-color);
    border-radius: 10px;
}

.action.dark {
    color: var(--third-bg-color);
}

.action-blue {
    color: var(--primary-action-text-color);
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-18);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--secondary-action-color);
    border-radius: 10px;
}

.outlined-blue {
    color: var(--secondary-action-color);
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-18);
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 2px solid var(--secondary-action-color);
    border-radius: 10px;
}

.action-text {
    color: var(--primary-action-color);
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-18);
    display: flex;
    gap: 16px;
    align-items: center;
}

.footer {
    font-size: var(--font-size-14);
    color: var(--third-text-color);
    font-weight: var(--font-weight-s);
    align-self: flex-start;
}

.footer:hover {
    color: var(--primary-action-text-color);
}

.icon {
    margin-left: 6px;
    display: inline-block;
    transform: translateY(2px);
}

@media(width <=1400px) {
    .icon {
        transform: translateY(3px) scale(0.85);
    }
}

@media(width <=1024px) {
    .icon {
        margin-left: 4px;
        transform: translateY(3px) scale(0.75);
    }
}