.social-links {
    display: flex;
    gap: 15px;
}

.placeholder {
    width: 35px;
    height: 35px;
    background: transparent;
}

@media(width <= 550px) {
    .placeholder {
        display: none;
    }
}