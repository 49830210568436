:root {
    --PhoneInputCountryFlag-height: 28px !important;
    --PhoneInputCountryFlag-borderColor: transparent !important;
    --PhoneInputCountrySelectArrow-width: 10px !important;
    --PhoneInputCountrySelectArrow-borderWidth: 4px !important;
    --PhoneInputCountrySelectArrow-opacity: 1 !important;
    --PhoneInputCountrySelectArrow-color: #fff !important;
}

.phone-number-input {
    margin-bottom: 34px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.phone-number-input .PhoneInput {
    display: flex;
    align-items: center;
    gap: 20px;
}

.phone-number-input .PhoneInputCountry {
    width: 110px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    background: var(--primary-select-bg);
    border-radius: 10px;
    margin: 0;
}

.phone-number-input .PhoneInputInput {
    padding: 22px 33px;
    border: none;
    width: 100%;
    height: 68px;
    color: var(--reversed-text-color);
    font-weight: var(--font-weight-m);
    font-size: var(--font-size-18);
    background: var(--primary-input-bg);
    border-radius: 16px;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.phone-number-input .PhoneInputInput::placeholder {
    color: var(--third-text-color);
    font-weight: var(--font-weight-m);
    font-size: var(--font-size-18);
}

.phone-number-input .PhoneInputInput:focus {
    outline: solid 1px var(--primary-action-color);
}

.phone-number-input-error.phone-number-input .PhoneInputInput:focus {
    outline: solid 1px var(--third-action-color);
}

.phone-number-input-error.phone-number-input .PhoneInputInput {
    outline: solid 1px var(--third-action-color);
}

.phone-number-input option {
    color: var(--reversed-text-color);
    font-weight: var(--font-weight-m);
    font-size: var(--font-size-18);
    cursor: pointer;
    background: var(--primary-input-bg);
}

@media(width <=1438px) {
    :root {
        --PhoneInputCountryFlag-height: 28px !important;
        --PhoneInputCountryFlag-borderColor: transparent !important;
        --PhoneInputCountrySelectArrow-width: 10px !important;
        --PhoneInputCountrySelectArrow-borderWidth: 4px !important;
        --PhoneInputCountrySelectArrow-opacity: 1 !important;
        --PhoneInputCountrySelectArrow-color: #fff !important;
    }

    .phone-number-input {
        margin-bottom: 24px;
    }

    .phone-number-input .PhoneInput {
        gap: 10px;
    }

    .phone-number-input .PhoneInputCountry {
        height: 58px;
    }

    .phone-number-input .PhoneInputInput {
        padding: 16px 0 15px 22px;
        height: 58px;
    }
}

@media(width <=1024px) {
    :root {
        --PhoneInputCountryFlag-height: 22px !important;
    }

    .phone-number-input {
        margin-bottom: 15px;
        gap: 8px;
    }

    .phone-number-input .PhoneInput {
        gap: 10px;
    }

    .phone-number-input .PhoneInputCountry {
        width: 85px;
        height: 58px;
        gap: 10px;
    }

    .phone-number-input .PhoneInputInput {
        padding: 16px 10px 15px 20px;
        height: 58px;
        font-size: var(--font-size-15);
    }

    .phone-number-input .PhoneInputInput::placeholder {
        font-size: var(--font-size-15);
    }

    .phone-number-input option {
        font-size: var(--font-size-15);
    }

    .reduced .phone-number-input .PhoneInputCountry {
        height: 48px;
    }

    .reduced .phone-number-input .PhoneInputInput {
        height: 48px;
        font-size: var(--font-size-14);
    }

    .reduced .phone-number-input .PhoneInputInput::placeholder {
        font-size: var(--font-size-14);
    }

    .reduced .phone-number-input option {
        font-size: var(--font-size-14);
    }
}