.faq {
    width: 100%;
    max-height: 95px;
    overflow: hidden;
    transition: all 1s;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.faq.open {
    max-height: 500px;
}

.question {
    width: 100%;
    height: 95px;
    padding: 0 37px 0 47px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: var(--primary-faq-color);
    border-radius: 20px;
    z-index: var(--separate-content-z-index);
    position: relative;
}

.question-text {
    font-size: var(--font-size-22);
}

.open .question {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    height: 88px;
    padding-top: 7px;
}

.answer {
    padding: 0 75px 52px 47px;
    background: var(--primary-faq-color);
    font-style: normal;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    transform: translateY(-100%);
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: all 1s;
    z-index: var(--separate-bg-z-index);
    position: relative;
}

.open .answer {
    transform: translateY(0%);
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
}

@media(width <=1400px) {
    .question-text {
        font-size: var(--font-size-26);
    }

    .open .question {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .answer {
        padding: 0 63px 52px 47px;
    }
}

@media(width <=1024px) {
    .faq {
        width: 100%;
        max-height: 60px;
        overflow: hidden;
        transition: all 1s;
    }

    .faq.open {
        max-height: 500px;
    }

    .question {
        width: 100%;
        height: 60px;
        padding: 0 18px 0 31px;
    }

    .question-text {
        font-size: var(--font-size-14);
        line-height: 30px;
    }

    .open .question {
        height: 60px;
        padding-top: 0;
    }

    .answer {
        padding: 8px 30px 23px;
        background: var(--primary-faq-color);
        font-size: 12px;
        line-height: 17px;
    }
}