* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sidebar_open {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}

::-webkit-scrollbar-track {
    background: #ccc;
    border-radius: 0 20px 20px 0;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(#00BD50, #089343);
    border-radius: 20px;
}

body:has(.ant-modal-mask) {
    overflow: hidden;
}

input,
button,
textarea,
select {
    margin: 0;
    font: inherit;
}

img {
    user-drag: none;
    user-select: none;
    -webkit-user-drag: none;
}

th {
    font-weight: 500;
}

.ant-picker-active-bar {
    display: none;
}

.ant-picker-range-arrow {
    display: none !important;
}

.ant-picker-range-separator {
    padding: 0 !important;
}

.ant-picker-date-range-wrapper button.ant-picker-header-super-prev-btn,
.ant-picker-date-range-wrapper button.ant-picker-header-super-next-btn {
    display: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start>div,
.ant-picker-cell-in-view.ant-picker-cell-range-end>div {
    background: rgba(0, 189, 80, 0.8) !important;
    border-radius: 12px !important;
    width: 34px;
    width: 34px;
}

td.ant-picker-cell:first-of-type::before,
td.ant-picker-cell.ant-picker-cell-range-end+.ant-picker-cell-disabled::before {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

td.ant-picker-cell:last-of-type::before,
td.ant-picker-cell.ant-picker-cell-disabled:has(+.ant-picker-cell-range-start)::before {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end+.ant-picker-cell.ant-picker-cell-today {
    border: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end+.ant-picker-cell::before {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.ant-picker-cell-in-range:has(+.ant-picker-cell-start)::before {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.ant-picker-cell-in-range+.ant-picker-cell-start::before {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

:not(.ant-picker-cell-disabled)+.ant-picker-cell-today::before {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.ant-select-selector {
    box-shadow: none !important;
    font-size: 13px !important;
    padding: 0 30px !important;
}


.ant-select-item.ant-select-item-option:hover {
    color: #00bd50 !important;
}



.ant-pagination-options .ant-select-item:first-child {
    margin-top: 12px;
}

.sm_select .ant-select-item:first-child {
    margin-top: 20px;
}

.xs_select .ant-select-item:first-child {
    margin-top: 13px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: transparent !important;
    color: #00bd50 !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: transparent !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: inherit !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-content {
    font-weight: 400 !important;
}

.ant-popover-inner {
    padding: 0 !important;
}

.select_container:has(.ant-select-open) {
    z-index: 6;
}

.ant-select-open .select_popup {
    z-index: 2 !important;
}

.ant-select-open .ant-select-arrow .suffix_icon {
    transform: scale(1, -1);
}

.user_select_input .ant-select-item {
    padding: 0 20px !important;
}

.user_select_input .ant-select-item:first-child {
    margin-top: 13px;
}


:where(.css-dev-only-do-not-override-dkbvqv).ant-popover-placement-bottomLeft>.ant-popover-arrow {
    transform-origin: top left;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-popover-placement-top>.ant-popover-arrow {
    bottom: 5px !important;
    margin-left: 3px;
}

:where(.css-dev-only-do-not-override-z234xi).ant-picker-dropdown .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none !important;
}

table tbody tr:last-child td .ant-select .select_popup {
    inset: unset !important;
    bottom: calc(100% - 42px) !important;
}

:where(.css-dev-only-do-not-override-dkbvqv)[class^="ant-modal"],
:where(.css-dev-only-do-not-override-dkbvqv)[class*=" ant-modal"],
:where(.css-dev-only-do-not-override-dkbvqv).ant-modal-root .ant-modal-centered .ant-modal {
    font-family: var(--font-family-main);
}

.ant-modal-close {
    width: 26px !important;
    height: 26px !important;
}

.for_des {
    display: block !important;
}

.for_mob {
    display: none !important;
}

.ant-select:hover .ant-select-arrow:not(:last-child) {
    opacity: 1;
}

[class*='ant-'] {
    font-family: var(--font-family-main);
    font-weight: 500;
}

.header_tooltip {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header_tooltip svg {
    cursor: pointer;
}

.tooltip_content h4 {
    color: 14px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 6px;
}

.tooltip_content p {
    font-size: 10px;
    line-height: 14px;
    color: var(--primary-text-color);
}

@media (min-width: 768px) {
    .ant-modal {
        padding: 8px;
    }
}

@media (max-width: 1024px) {
    :where(.css-dev-only-do-not-override-z234xi).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
        display: block;
    }

    :where(.css-dev-only-do-not-override-z234xi).ant-picker-dropdown {
        max-width: 290px;
    }

    :where(.css-dev-only-do-not-override-z234xi).ant-picker-dropdown .ant-picker-panel-container {
        max-height: 70vh;
        overflow-y: auto;
    }
}

@media (max-width: 991px) {
    .sidebar_open .sidebar {
        transform: translateX(0);
    }

    .user_mobile_select_input .ant-select-selector {
        padding: 0 6px !important;
        border-radius: 6px !important;
    }

    .user_mobile_select_input .ant-select-selection-item {
        padding-right: 0 !important;
    }

    .user_mobile_select_input .ant-select-arrow .suffix_icon {
        margin-right: 2px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #161414;
    }

    .user_mobile_select_input .ant-select-item.ant-select-item-option {
        padding: 0 15px !important;
    }

    .for_des {
        display: none !important;
    }

    .for_mob {
        display: block !important;
    }


}

@media (max-width: 575px) {

    .ant-pagination.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
        width: 20px;
        margin-left: auto;
    }

    .ant-pagination.ant-pagination-simple .ant-pagination-simple-pager {
        margin: 0;
    }

    .ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
        width: 38px;
    }
}