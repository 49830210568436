.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  padding: 2px;
  gap: 15px;
}

.info {
  flex-direction: column;
  text-align: right;
  display: flex;
}

.user-title {
  color: var(--primary-title-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.avatar {
  background-color: var(--primary-action-color);
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--primary-action-color-hover);
  }
}

.avatar_info .proxy_login {
  color: var(--secondary-action-color);
  font-family: var(--font-family-main);
  font-weight: 500;
  font-size: 12px;
}

.proxy_login_dropdown {
  >ul {
    overflow-x: auto;
    max-height: 72vh;
  }
}

.proxy_login_dropdown {
  >ul {
    overflow-x: auto;
    max-height: 72vh;
  }
}

.proxy_login_dropdown {
  >ul {
    overflow-x: auto;
    max-height: 50vh;
  }
}

.name-wrapper {
  display: flex;
  align-items: center;
  gap: 5px
}

.admin-badge {
  font-weight: 700;
  font-size: 10px;
  border-radius: 20%;
  padding: 0 4px;
  background: #ddd;
}

@media (max-width: 1000px) {
  .wrapper {
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: flex-end;
    max-width: 100%;
    border-bottom: 1px solid var(--primary-text-color);
    padding: 0 10px 10px 10px;
    border-radius: 10px;
  }

  .info {
    text-align: left;
  }

  .user-title {
    color: white;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ellipsis {
    width: 100%;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (max-width: 575px) {
  .wrapper {
    flex-grow: 1;
    justify-content: flex-end;
    flex-direction: row-reverse;
    max-width: 100%;
    border-bottom: 1px solid var(--primary-text-color);
    padding: 0 10px 10px 10px;
    border-radius: 10px;
    gap: 15px;
  }
}