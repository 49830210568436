.menu-item {
    padding: 17px 36px 18px;
    display: flex;
    align-items: center;
    gap: 22px;
    cursor: pointer;
    transition: all 0.3s;
    z-index: var(--separate-content-z-index);
    width: 380px;
}

.title {
    font-size: var(--font-size-20);
    line-height: 30px;
}

@media(width <=1400px) {
    .menu-item {
        padding: 17px 28px 18px;
        gap: 18px;
        width: 280px;
    }

    .title {
        font-size: var(--font-size-20);
        line-height: 30px;
    }
}

@media(width <=1024px) {
    .menu-item {
        padding: 14px 46px;
        gap: 18px;
        max-width: 333px;
        width: 100%;
        overflow: hidden;
    }

    .title {
        font-size: var(--font-size-16);
        line-height: 30px;
        width: 100%;
        flex-shrink: 0;
    }

    .icon {
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        transform: scale(0.8);
    }
}