:root {
    --font-family-main: 'Satoshi', sans-serif;
    --font-size-65: 65px;
    --font-size-60: 60px;
    --font-size-55: 55px;
    --font-size-50: 50px;
    --font-size-40: 40px;
    --font-size-38: 38px;
    --font-size-35: 35px;
    --font-size-32: 32px;
    --font-size-28: 28px;
    --font-size-26: 26px;
    --font-size-25: 25px;
    --font-size-24: 24px;
    --font-size-23: 23px;
    --font-size-22: 22px;
    --font-size-20: 20px;
    --font-size-19: 19px;
    --font-size-18: 18px;
    --font-size-16: 16px;
    --font-size-15: 15px;
    --font-size-14: 14px;
    --font-size-13: 13px;
    --line-height-76: 76px;
    --line-height-68: 68px;
    --line-height-55: 55px;
    --line-height-50: 50px;
    --line-height-45: 45px;
    --line-height-36: 36px;
    --line-height-24: 24px;
    --line-height-21: 21px;
    --font-weight-xl: 900;
    --font-weight-l: 700;
    --font-weight-m: 500;
    --font-weight-s: 400;
    --header-height: 85px;
    --footer-height: 60px;
    --menu-width: 280px;
    --modal-z-index: 50;
    --overlay-z-index: 45;
    --header-z-index: 40;
    --select-z-index: 35;
    --select-overlay-z-index: 30;
    --separate-content-z-index: 5;
    --separate-bg-z-index: 2;
    --phone-breakpoint: 1022px;
    --max-width: 1422px;
}

@media (width <=1400px) {
    :root {
        --font-size-65: 52px;
        --font-size-60: 40px;
        --font-size-55: 36px;
        --font-size-50: 34px;
        --font-size-40: 32px;
        --font-size-38: 32px;
        --font-size-35: 28px;
        --font-size-32: 26px;
        --font-size-28: 22px;
        --font-size-26: 20px;
        --font-size-25: 18px;
        --font-size-24: 18px;
        --font-size-23: 18px;
        --font-size-22: 16px;
        --font-size-20: 16px;
        --font-size-19: 15px;
        --font-size-18: 15px;
        --font-size-16: 14px;
        --font-size-15: 12px;
        --font-size-14: 12px;
        --font-size-13: 10px;
        --header-height: 70px;
        --menu-width: 256px;
    }
}

@media (width <=1024px) {
    :root {
        --footer-height: 101px;
    }
}

@media (width <=991px) {
    :root {
        --font-size-65: 65px;
        --font-size-60: 60px;
        --font-size-55: 55px;
        --font-size-50: 50px;
        --font-size-40: 40px;
        --font-size-38: 38px;
        --font-size-35: 35px;
        --font-size-34: 34px;
        --font-size-32: 32px;
        --font-size-30: 30px;
        --font-size-28: 28px;
        --font-size-26: 26px;
        --font-size-25: 25px;
        --font-size-24: 24px;
        --font-size-23: 23px;
        --font-size-22: 22px;
        --font-size-20: 20px;
        --font-size-19: 19px;
        --font-size-18: 18px;
        --font-size-16: 16px;
        --font-size-15: 15px;
        --font-size-14: 14px;
        --font-size-13: 13px;
        --font-size-12: 12px;
        --font-size-10: 10px;
        --menu-width: 270px;
    }
}
