.content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 244px;
    padding: 15px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    button {
        height: 40px;
        width: 102px;
        color: white;
        font-size: 13px;
        font-weight: 500;

        &.cancelButton {
            background-color: #d9d9d9;
            color: var(--first-text-color);
        }

        &.confirmButton {
            background-color: var(--third-action-color);
        }
    }
}