.container {
    margin-left: var(--menu-width);
    margin-top: var(--header-height);
    padding: 0 44px;
    width: calc(100% - var(--menu-width));
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.content {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    margin-bottom: var(--footer-height);
    padding-bottom: 24px;
}

@media (max-width: 1400px) {
    .container {
        margin-top: calc(var(--header-height) + 20px);
        min-height: calc(100vh - var(--header-height) - var(--footer-height) - 20px);
    }

    .content {
        margin-bottom: 0;
    }
}

@media (max-width: 1024px) {
    .container {
        padding: 0 25px;
    }
}

@media (max-width: 991px) {
    .container {
        padding: 0 30px;
        margin: 0;
        width: 100%;
        min-height: auto;
        margin-top: 18px;
    }
}

/* @media (max-width: 350px) {
    .container {
        padding: 0 15px;
    }
} */