.menu-items {
    list-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 5px;
}

.select {
    width: 380px;
    height: 76px;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--primary-bg-color);
    z-index: var(--separate-bg-z-index);
    transition: all 0.3s;
}

.dark .select {
    background: var(--reversed-bg-color);
}

@media(width <=1400px) {
    .menu-items {
        padding-top: 0;
    }

    .select {
        width: 280px;
        height: 64px;
    }
}

@media(width <=1024px) {
    .menu-items {
        padding-top: 0;
    }

    .select {
        width: 100%;
        max-width: 333px;
        height: 60px;
        border-radius: 16px;
    }
}