.light {
    --primary-bg-color: #FFF;
    --secondary-bg-color: #F3F3F3;
    --third-bg-color: #272424;
    --fourth-bg-color: #2D2A2A;
    --reversed-bg-color: #161414;
    --primary-title-color: #161414;
    --reversed-title-color: #FFF;
    --primary-text-color: #595959;
    --secondary-text-color: #000;
    --third-text-color: #8A8A8A;
    --reversed-text-color: #FFF;
    --disabled-text-color: #C2C2C2;
    --primary-action-color: #00BD50;
    --primary-action-color-hover: #089343;
    --primary-action-color-disabled: #9CDEAA;
    --secondary-action-color: #2B73FF;
    --secondary-action-color-disabled: #BFD5FF;
    --third-action-color: #F4363F;
    --fourth-action-color: #FFA301;
    --primary-action-text-color: #FFF;
    --secondary-action-text-color: #F4363F;
    --primary-action-color-blurred: #00BD501A;
    --primary-icon-bg: #2D2A2A;
    --primary-input-bg: #292626;
    --primary-divider-color: #E7E7E7;
    --primary-faq-color: #F3F3F3;
    --primary-select-bg: #423D3D;
    --primary-option-button-bg: #EEE;
    --border_color: rgba(217, 217, 217, 0.5);

    --notice-info-bg: rgba(42, 115, 255, .1);
    --notice-info-text: #2D2A2A;

    --notice-warning-bg: rgba(255, 178, 1, .2);
    --notice-warning-text: #2D2A2A;

    --separator: #353535;
    --sidebar-gradient: linear-gradient(to right, #005720, transparent 80%);
    --sidebar-parent-padding: 20px;
    --sidebar-child-padding: 50px;
}