.user_box {
	padding: 15px clamp(0.938rem, 1.49vw + 0.639rem, 1.563rem);
	margin-bottom: 8px;
}

.user_box_top {
	margin-bottom: 13px;
	padding-bottom: 13px;
	border-bottom: 1px solid var(--border_color);
}

.user_box_top,
.user_box_bottom {
	display: flex;
	justify-content: space-between;
	gap: clamp(0.75rem, 1.192vw + 0.512rem, 1.25rem);
}

.user_box p,
.user_box a {
	font-size: 14px;
	font-weight: 500;
}

.user_name {
	width: 50%;
}

.user_name p b {
	display: block;
	font-weight: 500;
}

.user_box_label {
	color: var(--third-text-color);
	margin-bottom: 6px;
	display: block;
}

.user_badge {
	padding: 6px clamp(0.75rem, 1.192vw + 0.512rem, 1.25rem);
	background-color: rgb(42, 115, 255, 0.1);
	color: var(--secondary-action-color);
	border-radius: 100vmax;
	display: inline-block;
}

.user_status div {
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: 500;
	margin-top: 12px;
}

.active {
	color: var(--primary-action-color);
}

.active:before {
	content: '';
	background-color: var(--primary-action-color);
	height: 8px;
	width: 8px;
	display: inline-block;
	border-radius: 50%;
	margin-bottom: 1px;
}

.inactive {
	color: var(--third-action-color);
}

.inactive:before {
	content: '';
	background-color: var(--third-action-color);
	height: 8px;
	width: 8px;
	display: inline-block;
	border-radius: 50%;
	margin-bottom: 1px;
}

.pending {
	color: var(--fourth-action-color);
}

.pending:before {
	content: '';
	background-color: var(--fourth-action-color);
	height: 8px;
	width: 8px;
	display: inline-block;
	border-radius: 50%;
	margin-bottom: 1px;
}

.user_action,
.user_invoice {
	text-align: right;
}

.user_action button {
	background: transparent;
	padding: 0;
	border: none;
	outline: none;
	margin-top: 5px;
}

.user_action button svg path {
	fill: var(--third-action-color);
}

.user_email a {
	text-decoration: none;
	display: block;
	color: var(--secondary-action-color);
}

.textInput,
.selectInput {
	padding: 0;
	height: 38px;
	border-radius: 6px;
	text-align: left;
	background-color: var(--secondary-bg-color);
}

.textInput {
	padding: 0 10px;
	margin-top: 4px;
	font-size: 12px;
}

.user_name_input {
	max-width: 160px;
}

.user_name_input:first-of-type .textInput {
	margin-top: 0;
}

.user_role {
	width: 120px;
}

.user_invoice {
	width: 120px;
}

@media (max-width: 575px) {

	.user_box p,
	.user_box a {
		font-size: 12px;
	}
}