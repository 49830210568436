/* Sidebar Wrapper and Overlay */
.wrapper {
    .sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: var(--primary-title-color);
        width: var(--menu-width);
        position: fixed;
        height: 100%;

        @media (--max-xl) {
            display: none;
            width: 50%;
        }

        @media (--max-lg) {
            width: 100%;
        }

        &[data-is-loading='true'] {
            background: transparent;
        }

        &[data-is-menu-open='true'] {
            display: flex;
            z-index: 20;
        }
    }

    .overlay {
        opacity: 0;
        left: -100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.45);
        -webkit-backdrop-filter: blur(8px);
        transition: opacity 0.3s ease;
        backdrop-filter: blur(8px);
        cursor: pointer;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    &[data-is-mobile-open='true'] {
        .overlay {
            left: 0;
            display: flex;
            opacity: 1;
        }
    }
}

/* Sidebar Header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;

    @media (--max-lg) {
        padding: 20px 20px 30px;
    }

    button {
        display: none;
        background: transparent;
        cursor: pointer;
        border: none;

        @media (--max-xl) {
            display: block;
        }
    }
}

/* Sidebar Navigation */
.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    height: 100%;
    gap: 40px;

    /* Profile (mobile) */
    .profile {
        display: none;

        @media (--max-xl) {
            padding: 0 var(--sidebar-parent-padding);
            display: block;
        }
    }

    nav {
        flex-grow: 1;
    }

    &::-webkit-scrollbar-track,
    scrollbar-track {
        background-color: transparent;
    }

    section {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        color: white;

        &:nth-child(1) {
            margin-top: 0;
        }

        .parent div {
            display: flex;
            padding: 15px var(--sidebar-parent-padding);
            font-weight: 500;
            font-size: 16px;
            line-height: 21.6px;
            gap: 10px;
        }

        .parent {
            cursor: default;
            text-transform: uppercase;
            letter-spacing: 3px;
            padding: 0;

            a {
                padding: 0;
                text-transform: none;
                letter-spacing: 0.02rem;
                cursor: pointer;
            }
        }

        a {
            padding: 12px var(--sidebar-parent-padding) 12px
                var(--sidebar-child-padding);
            color: var(--reversed-text-color);
            text-decoration: none;
            transition: all 0.3s ease;
            cursor: pointer;
            font-weight: 500;
            font-size: 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &:hover,
            &[data-active='true'] {
                background: var(--sidebar-gradient);
                background-size: 150% 100%;
                background-position: right bottom;
                transition: all 0.5s ease;
                color: var(--primary-action-color);
            }

            &[data-active='true'] {
                background-size: auto;
                background-position: 0;
            }

            .badges {
                display: flex;
                flex-direction: row;
                gap: 8px;
            }
        }
    }
}
