.text {
    color: var(--primary-text-color);
    font-weight: var(--font-weight-s);
}

.title {
    font-weight: var(--font-weight-xl);
    color: var(--primary-title-color);
}

.title-reversed {
    font-weight: var(--font-weight-xl);
    color: var(--reversed-title-color);
}

.title-large {
    font-weight: var(--font-weight-xl);
    font-size: var(--font-size-55);
    color: var(--primary-title-color);
}

.title-large-reversed {
    font-weight: var(--font-weight-xl);
    font-size: var(--font-size-55);
    color: var(--reversed-text-color);
}

.title-medium {
    font-weight: var(--font-weight-xl);
    font-size: var(--font-size-50);
    color: var(--primary-title-color);
}

.title-medium_reversed {
    font-weight: var(--font-weight-xl);
    font-size: var(--font-size-50);
    color: var(--reversed-text-color);
}

.title-extra-small {
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-35);
    color: var(--primary-title-color);
}

.title-extra-small-reversed {
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-35);
    color: var(--reversed-title-color);
}

.subtitle-medium {
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-20);
    color: var(--primary-title-color);
}

.subtitle-medium-reversed {
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-20);
    color: var(--reversed-text-color);
}

.subtitle-small {
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-18);
    color: var(--primary-title-color);
}

.paragraph-title-medium {
    color: var(--third-text-color);
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-m);
}

.paragraph-title {
    color: var(--third-text-color);
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-m);
}

.paragraph-dark-medium {
    color: var(--primary-text-color);
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-m);
}

.paragraph-dark {
    color: var(--primary-text-color);
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-m);
}

.paragraph-disabled {
    color: var(--disabled-text-color);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-m);
}

.paragraph-black {
    color: var(--secondary-text-color);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-s);
    font-style: italic;
}

.paragraph-reversed {
    color: var(--reversed-text-color);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-s);
    line-height: 20px;
}

.paragraph-reversed-small {
    color: var(--reversed-text-color);
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-m);
    line-height: 18px;
}

.action {
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-18);
    color: var(--primary-action-color);
}

.action-red {
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-18);
    color: var(--secondary-action-text-color);
}

.badge {
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-16);
    color: var(--primary-action-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 37px;
    border-radius: 10px;
    background: var(--primary-action-color-blurred);
    width: max-content;
}

.badge-blue {
    font-weight: var(--font-weight-m);
    font-size: var(--font-size-18);
    color: var(--secondary-action-color);
}

.default {
    font-size: var(--font-size-16);
}

.default-small {
    font-size: var(--font-size-14);
    color: var(--third-text-color);
}

.subheader-light {
    color: var(--reversed-title-color);
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-20);
}

.subheader-light-small {
    color: var(--reversed-title-color);
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-16);
    line-height: 18px;
}

.card-title {
    font-weight: var(--font-weight-l);
    font-size: var(--font-size-16);
    color: var(--primary-title-color);

}

@media(width <= 1022px) {
    .badge {
        font-size: var(--font-size-12);
        padding: 7px 27px;
        height: 36px;
        width: 130px;
    }
}