.button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    height: 62px;
    background: var(--primary-action-color);
    color: var(--reversed-text-color);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-l);
    outline: none !important;
    transition: 0.3s;
}

.button:disabled {
    background: var(--secondary-action-color-disabled);
    color: var(--primary-bg-color);
    cursor: not-allowed;

    &:hover {
        background: var(--secondary-action-color-disabled);
        opacity: 0.8
    }
}

/* .action,
.outlined,
.action-blue,
.option,
.action-red {
    border-radius: 10px;
    width: 100%;
    height: 62px;
    background: var(--primary-action-color);
    color: var(--reversed-text-color);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-l);
    outline: none !important;
    transition: 0.3s;
} */

.action:hover {
    background: var(--primary-action-color-hover);
}

.option {
    background: var(--primary-option-button-bg);
    color: var(--primary-title-color);
}

.outlined {
    background: transparent;
    color: var(--primary-action-color);
    border: solid 2px var(--primary-action-color);
}

.action-blue {
    background: #2B73FF;
    color: white;
    font-size: 10px;
    font-weight: 700;
    border: none;
}

.action-blue:hover {
    background: #1c62e9;
}

.action-red {
    background: var(--third-action-color);
}

.action-red:hover {
    background: #cd272f;
}

.text {
    color: var(--primary-action-color);
    font-weight: var(--font-weight-l);
    border-radius: 10px;
    font-size: var(--font-size-18);
    background: transparent;
}

.text:hover {
    background: rgb(0 189 80 / 10%);
}

.close {
    background: transparent;
    padding: 0;
    margin: 0;
}

.disabled {
    background: rgb(255 255 255 / 20%);
    cursor: auto;
}

@media(width <=1438px) {

    .button {
        height: 56px;
    }

    .endIcon {
        transform: scale(0.8) translateY(2px);
    }
}

.start-icon {
    margin-right: 8px;
}