.flag {
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
}

@media(width <=1400px) {
    .flag {
        height: 48px;
    }
}

@media(width <=1024px) {
    .flag {
        height: 48px;
    }
}