.container {
    position: relative;
    width: 100%;
}

.container:has(.label) {
    padding-top: 14px;
    margin-top: 10px;
}

.label {
    position: absolute;
    top: 0;
    left: 21px;
    background-color: white;
    border-radius: 100vmax;
    color: var(--fourth-bg-color);
    font-weight: var(--font-weight-l);
    font-size: 11px;
    line-height: 27px;
    padding: 0px 18px;
    height: 24px;
    display: flex;
    align-items: center;
    z-index: 3;
    font-family: var(--font-family-main);
}

.select {
    width: 100%;
    height: 68px;
    font-family: var(--font-family-main);
}

.select>div:not(.popup) {
    background-color: var(--secondary-bg-color) !important;
    border: none !important;
    border-radius: 16px !important;
}

.select>div:not(.popup) span {
    padding-left: 4px !important;
    font-weight: 500;
}

.select.error:not(:focus) {
    border: 1px solid var(--secondary-action-text-color);
    border-radius: 16px;
}

.select.sm_select {
    height: 52px;
    font-size: 14px;
    border-radius: 14px;
}

.select.sm_select>div:not(.popup) {
    border-radius: 14px !important;
}

.select.sm_select::placeholder {
    font-size: 14px;
}

.select.xs_select {
    height: 46px;
    font-size: 12px;
    border-radius: 10px;
}

.select.xs_select>div:not(.popup) {
    border-radius: 10px !important;
}

.select.xs_select::placeholder {
    font-size: 12px;
}

.popup {
    inset: 0 auto auto 0 !important;
    padding: 0;
    background: #fff !important;
}

.disable_select .select>div {
    background: rgba(217, 217, 217, 1) !important;
}

.disable_select .select span {
    color: rgba(22, 20, 20, 1);
}

.suffix {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #161414;
    margin-right: 12px;
}

.option:hover {
    border-radius: 16px;
    z-index: 3;
    transition: 0.3s;
}

.error-message {
    font-size: 13px !important;
    padding-left: 18px;
    padding-top: 2px;
    color: var(--secondary-action-text-color);
}

@media (max-width: 1024px) {
    .container:has(.label) {
        margin-top: 10px;
    }
}

@media (max-width: 575px) {
    .container:has(.label) {
        padding-top: 14px;
        margin-top: 6px;
    }

    .select {
        height: 58px;
        font-size: var(--font-size-14);
        font-size: 14px;
    }

    .label {
        left: 17px;
        font-size: 12px;
        padding: 0px 14px;
        height: 26px;
    }

    .suffix {
        margin-right: 6px;
        border-width: 6px;
    }
}