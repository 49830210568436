.header {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    left: var(--menu-width);
    width: calc(100% - var(--menu-width));
    height: var(--header-height);
    background: transparent;
    top: 0;
    z-index: var(--header-z-index);
    padding: 0 44px;
}

.content {
    display: flex;
    flex: 1;
    max-width: var(--max-width);
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 100%;
    gap: 12px;
}

.icon {
    margin-right: 10px;
}

.actions {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    flex: 1;
    flex-wrap: wrap;

    h1 {
        height: auto;
        margin: 0;
    }
}


.mobile_header_top {
    display: none;
}

.action_title {
    font-size: 26px;
    line-height: 35px;
    /* margin-top: 34px; */
}

@media (max-width: 1024px) {
    .header {
        padding: 0 25px;
    }
}

@media (max-width: 991px) {
    .header {
        padding: 0 30px;
        position: unset;
        height: auto;
        width: 100%;
        gap: 6px
    }

    .mobile_header_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
    }

    .header h1 {
        font-size: 22px;
        margin-top: 9px;
    }

    .header_toggle {
        background: transparent;
        outline: none !important;
        border: none !important;
        width: 25px;
        height: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
    }

    .header_toggle span {
        width: 100%;
        height: 2px;
        background: var(--reversed-bg-color);
        display: block;
    }
}

@media (max-width: 1000px) {
    .hideOnMobile {
        display: none;

        .profileMenu {
            display: none;
        }
    }
}