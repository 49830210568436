.footer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .logout {
        border: none;
        background: transparent;
        padding: 12px var(--sidebar-parent-padding);
        color: var(--reversed-text-color);
        transition: all 0.3s ease;
        text-decoration: none;
        cursor: pointer;
        font-weight: 500;
        font-size: 15px;
        flex-direction: row;
        align-items: center;
        display: flex;
        gap: 10px;

        &:hover {
            color: var(--primary-action-color);
            background: var(--sidebar-gradient);
            background-position: right bottom;
            background-size: 150% 100%;
            transition: all 0.5s ease;
        }
    }

    .links {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--separator);
        padding: 20px 0;
        margin: 15px;
        gap: 10px;

        @media (--max-lg) {
            padding: 20px 0 0 0;
            gap: 5px;
        }

        a,
        span {
            padding: 0 10px;
        }

        a {
            color: var(--reversed-text-color);
            text-decoration: none;
            line-height: 18.9px;
            letter-spacing: 0px;
            font-weight: 500;
            font-size: 14px;

            &:hover {
                color: var(--primary-action-color);
            }
        }

        span {
            line-height: 13.5px;
            letter-spacing: 0px;
            font-weight: 500;
            font-size: 10px;
            color: #888888;
        }
    }
}
